import React from 'react';
import { NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { useMicroApp } from '../../hooks/useMicroApp';

export const MicroApp = () => {
  const { component } = useMicroApp();
  return (
    <>
      <NoIndexNoFollowMetadata />
      { component }
    </>
  );
};

MicroApp.propTypes = {
};

MicroApp.displayName = 'MicroApp';

MicroApp.defaultProps = {
  name: '',
};
