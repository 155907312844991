import React, { useState, useEffect } from 'react';
import { Error as ErrorPage } from '../pages/Error';

export const useMicroApp = () => {
  const [component, setComponent] = useState(null);

  const setPageContext = async () => {
    const microAppComponent = await import(/* webpackChunkName: "microApp" */ '../pages/microApp/MicroAppExperience');
    setComponent(<microAppComponent.MicroAppExperience />);
  };

  useEffect(() => {
    try {
      setPageContext();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error ==> ', e);
      setComponent(<ErrorPage error={e} debug={window.__EXPERIENCE_CONTEXT__.isDebugMode} />);
    }
  }, []);

  return { component };
};
