import { Tag } from './Tag';

class MetaTag extends Tag {
  constructor(attributes) {
    super();
    this.type = 'meta';
    this.prefix = 'thd-helmet__meta--';
    this.attributes = attributes;
    if (!attributes.id) {
      console.warn('should should specify and ID to meta tags');
    } else {
      this.attributes.id = `${this.prefix}${this.attributes.id}`;
    }
  }

  updateDom() {
    let el = document.getElementById(this.attributes.id);
    if (!el) {
      el = document.createElement('meta');
      document.head.appendChild(el);
    }
    Object.keys(this.attributes).forEach((attr) => {
      el.setAttribute(attr, this.attributes[attr]);
    });
  }

  removeFromDom() {
    if (this.attributes.id) {
      const el = document.getElementById(this.attributes.id);
      if (el && el.remove) {
        el.remove();
      }
    }
  }

  toString() {
    const values = Object.keys(this.attributes).map((key) => {
      return `${key}="${this.attributes[key]}"`;
    }).join(' ');
    return `<meta data-th="server" ${values}>`;
  }
}

export { MetaTag };
